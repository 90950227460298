import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { accountBalance, referralBalance, bonusBalance, currentInvestment } from '../Request/Wallets';
// import {currentEarning} from '../Request/Wallets';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Clock from '../Clock';
import CurrentTrade from './CurrentTrade';
import Analysis from '../Home/Analysis';
const EarningSlides = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
            }
          }
        ]
      };


    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());
    const [currentInvested, setcurrentInvested] = useState(currentInvestment());


       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.ref_bal);
        }
        return balance;
    
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }

      const newCurrentInvestment = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.current_investment;
        }
        return balance;
      }


    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }    

    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        setreferralBalance(newreferralBalance());
        setbonusBalance(newbonusBalance());
        setcurrentInvested(newCurrentInvestment());
        
  }, false);
    });
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

   return (
<div className='container px-sm-4 px-0 ' style={{marginTop: '40px'}}>    
    
    <div className='row'>
        {/* <div className='col-12 col-md-12'>
        <div className="text-center mt-n2 mb-2 px-4 d-flex justify-content-center">
            <div className="color-theme font-26">BALANCE:</div>
            <div className="color-theme font-26 mx-4">{getAmount(accBalance)}</div>
        </div>
        </div> */}
        <div className='col-12 col-md-12'>
            <Clock/>
            <CurrentTrade/>
        </div>
        <div className='col-6 col-md-6'>
        <div className="card bg-theme color-theme shadow-sm" style={{height: "150px", borderRadius: '10px'}}>
                <div className="card-center">
                    <div className="bg-theme px-1 py-2 rounded-end">
                        <div className="" id="fundingWallet"><h2 className="color-theme text-center font-24">{getAmount(currentInvested)}</h2></div>
                    </div>
                </div>
                <strong className="card-top no-click font-14 text-center p-1 font-monospace font-weight-bold">AMOUNT INVESTED</strong>
                <div className="card-overlay "></div>
            </div>
        </div>
        
        <div className='col-6 col-md-6'>
            <div className="card bg-theme color-theme shadow-sm" style={{height: "150px", borderRadius: '10px'}}>
                <div className="card-center">
                    <div className="bg-theme px-1 py-2 rounded-end">
                        <div className="" id="fundingWallet"><h2 className="color-theme text-center font-24">{getAmount(refBalance)}</h2></div>
                    </div>
                </div>
                <strong className="card-top no-click font-14 text-center p-1 font-monospace font-weight-bold">REFERRAL EARNING</strong>
                <div className="card-overlay "></div>
            </div>
        </div>
        
    </div>
</div>
  )
}

export default EarningSlides